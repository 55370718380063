import styled from 'styled-components';

export const HeadingOne = styled.h1`
  font-size: 4.4rem;
  font-weight: ${({ theme }) => theme.thin};
  text-transform: uppercase;
  ${({ theme }) => theme.mq.s} {
    font-weight: ${({ theme }) => theme.thin};
    font-size: 6.4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 8rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 9.3rem;
  }
  em {
    &:nth-child(1) {
      color: #128601;
    }
    &:nth-child(2) {
      color: #fb0000;
    }
  }
`;

export const HeadingTwo = styled.h2`
  font-size: ${({ theme }) => `calc(${theme.fontSize.xxl} - 2.5rem)`};
  font-weight: ${({ theme }) => theme.bold};
  text-align: ${({ align }) => align || 'center'};
  padding: ${({ nopadding }) => (nopadding ? '0' : '0 0 45px')};
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xxl} - 2rem)`};
  }
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xxl} - 1rem)`};
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }
`;
