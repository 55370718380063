import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import decodeHtmlEntity from 'utils/decodeHtmlEntity';

function SEO({ lang, meta, title }) {
  const {
    wpgraphql: {
      page: { seo },
    },
  } = useStaticQuery(
    graphql`
      query querySEO {
        wpgraphql {
          page(id: "/strona-glowna/", idType: URI) {
            seo {
              metaDesc
              opengraphDescription
              opengraphTitle
              opengraphSiteName
              opengraphImage {
                sourceUrl
              }
            }
          }
        }
      }
    `
  );

  const ogImage = seo.opengraphImage
    ? { property: `og:image`, content: seo.opengraphImage.sourceUrl }
    : {};

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={decodeHtmlEntity(title)}
      titleTemplate={`%s - ${decodeHtmlEntity(seo.opengraphSiteName)}`}
      meta={[
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `description`,
          content:
            decodeHtmlEntity(seo.metaDesc) ||
            decodeHtmlEntity(seo.opengraphDescription),
        },
        {
          property: `og:title`,
          content: decodeHtmlEntity(seo.opengraphTitle),
        },
        {
          property: `og:description`,
          content:
            decodeHtmlEntity(seo.opengraphDescription) ||
            decodeHtmlEntity(seo.metaDesc),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: decodeHtmlEntity(seo.opengraphTitle),
        },
        {
          name: `twitter:description`,
          content:
            decodeHtmlEntity(seo.metaDesc) ||
            decodeHtmlEntity(seo.opengraphDescription),
        },
        ogImage,
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
